
import { useStore } from 'vuex';
import { useGtag } from 'vue-gtag-next';
import { defineComponent, computed, ref, nextTick, PropType } from 'vue';
import useBreakpoints from '@/use/useBreakpoints';

import { Consumer } from '@/modules/consumer/types';

import Icon from '@/components/Icon/Icon.vue';
import Input from '@/components/Input/Input.vue';
import Button from '@/components/Button/Button.vue';
import RedemptionInfo from '@/components/CodeRedemption/RedemptionInfo.vue';

export default defineComponent({
  components: {
    Icon,
    Input,
    Button,
    RedemptionInfo,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    consumer: {
      type: Object as PropType<Consumer>,
      default: () => ({}),
    },
    shouldShowForm: Boolean,
  },
  setup() {
    const store = useStore();
    const { event } = useGtag();
    const breakpointsService = useBreakpoints();

    const shouldShowInfo = ref(false);
    const isMobile = ref(!breakpointsService.isMd());
    nextTick(() => { shouldShowInfo.value = true; });

    const voucher = ref(null);
    const errors = ref([]);
    const isLoading = computed(() => store.getters['voucherModule/isLoading']);
    const formError = computed(() => store.getters['voucherModule/formError']);
    const pointsGranted = computed(() => store.getters['voucherModule/pointsAwarded']);
    const isOnlyStatusPoint = computed(() => store.getters['voucherModule/isOnlyStatusPoint']);

    const attemptBurnVoucher = async () => {
      event('Attempt Redeem Voucher', {
        event_category: 'VoucherRedemption',
        event_label: `Attempted to use voucher code: ${voucher.value}.`,
      });

      if (voucher.value) {
        await store.dispatch(
          'voucherModule/redeemVoucher',
          {
            voucher: voucher.value,
            fallbackError: 'Dieser Code wurde bereits eingelöst.',
          },
        );

        event('Voucher Successfully Redeem', {
          event_category: 'VoucherRedemption',
          event_label: `Voucher: ${voucher.value} was redeemed successfully.`,
        });
      }
    };

    const handleInput = (val) => { voucher.value = val; };

    return {
      errors,
      voucher,
      isMobile,
      isLoading,
      formError,
      handleInput,
      pointsGranted,
      isOnlyStatusPoint,
      shouldShowInfo,
      attemptBurnVoucher,
    };
  },
});
