<template>
<div class="content-intro">
  <div class="content-intro__content">
    <slot />
  </div>
</div>
</template>

<style lang="scss">
  .content-intro {
    @apply bg-primary-soft-white;

    &__content {
      @apply mx-auto
             px-xxl-2
             md:px-0
             py-xxl-5
             lg:py-xxl-6
             xxl:py-xxl-7
             font-bold
             text-center
             text-fs-24-lh-36-ls-0
             md:text-fs-28-lh-36-ls-0
             xl:text-fs-32-lh-42-ls-0;

      @screen md {
        max-width: 584px;
      }
      @screen lg {
        max-width: 616px;
      }
      @screen xl {
        max-width: 752px;
      }
      @screen xxl {
        max-width: 864px;
      }
    }
  }
</style>