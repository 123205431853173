import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/svgs/icon-notification-signal-error.svg'


const _hoisted_1 = { class: "input" }
const _hoisted_2 = {
  key: 0,
  class: "block mb-m text-sm"
}
const _hoisted_3 = { class: "relative block" }
const _hoisted_4 = ["id", "type", "disabled", "placeholder", "aria-label", "value"]
const _hoisted_5 = {
  key: 0,
  class: "w-6 h-auto absolute right-s top-s",
  src: _imports_0,
  alt: "Error notification"
}
const _hoisted_6 = {
  key: 1,
  class: "mt-xs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.id,
        type: _ctx.type,
        disabled: _ctx.disabled,
        placeholder: _ctx.label,
        class: _normalizeClass(["w-full h-12 text-base py-s px-m rounded outline-none shadow-none ring-0", [
          _ctx.forceFocus
            ? 'border-transparent ring-2 ring-primary-slate'
            : _ctx.hasErrors
            ? 'border-alerts-error-red border-2'
            : `
              border border-tints-slate-30
              focus:border-transparent focus:ring-2 focus:ring-primary-slate`,
          _ctx.inputClasses,
        ]]),
        "aria-label": _ctx.label,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
      }, null, 42, _hoisted_4),
      (_ctx.hasErrors)
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.disabled)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 1,
            class: "w-6 h-auto absolute right-s top-s",
            type: "lock",
            theme: "light"
          }))
        : _createCommentVNode("", true)
    ]),
    (_ctx.hasErrors)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorMessages, (message, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "text-alerts-error-red text-xs"
            }, _toDisplayString(message), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}