
import { defineComponent, computed } from 'vue';

import Icon from '@/components/Icon/Icon.vue';

const DEFAULT_VMODEL_EVENT = 'update:modelValue';

export default defineComponent({
  components: { Icon },
  props: {
    errorMessages: {
      type: Object as () => string[],
      default: [],
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    inputClasses: {
      type: String,
      default: '',
    },
    /** Default value name that Vue3 uses for correct use of v-model directive */
    modelValue: {
      type: String,
      default: '',
    },
    /** Mimic type attribute of HTML input tag */
    type: {
      type: String,
      default: 'text',
    },
    // Testing helpers
    forceFocus: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  emits: [DEFAULT_VMODEL_EVENT],
  setup(props, { emit }) {
    const hasErrors = computed<boolean>(() => props.errorMessages.length > 0);

    const handleInput = (event: Event) => {
      const { value } = event.target as HTMLInputElement;
      // Emit changes
      emit(DEFAULT_VMODEL_EVENT, value);
    }

    return {
      handleInput,
      hasErrors,
    }
  }
});
