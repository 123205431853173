import mapValues from 'lodash/mapValues';

import useTailwind from '@/use/useTailwind';

const tailwindTheme = useTailwind().theme;

interface BreakpointsMap {
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

interface UseBreakpoints {
  isSm: () => boolean;
  isMd: () => boolean;
  isLg: () => boolean;
  isXl: () => boolean;
  list: () => number[];
  breakpoints: BreakpointsMap;
}

const getCurrentWidth = (): number => window.innerWidth;

// TODO: Fix this any to work with an object like { sm: 100, md: 200, lg: 300 }
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const breakpoints: any = mapValues(tailwindTheme.screens, parseInt);

const isSm = (): boolean => true;
const isMd = (): boolean => getCurrentWidth() >= breakpoints.md;
const isLg = (): boolean => getCurrentWidth() >= breakpoints.lg;
const isXl = (): boolean => getCurrentWidth() >= breakpoints.xl;

const list = (): number[] => Object.values(breakpoints);

export default (): UseBreakpoints => {
  return {
    isSm,
    isMd,
    isLg,
    isXl,
    list,
    breakpoints,
  };
};
