
import { useStore } from 'vuex';
import { useGtag } from 'vue-gtag-next';
import { defineComponent, computed } from 'vue';

import Button from '@/components/Button/Button.vue';
import StatusPill from '@/components/StatusPill/StatusPill.vue';
import ContentIntro from '@/components/ContentIntro/ContentIntro.vue';
import CodeRedemption from '@/components/CodeRedemption/CodeRedemption.vue';

import contentBlockFramedImage from '../assets/content-block-framed-class-completed.jpg'

export default defineComponent({
  components: {
    Button,
    StatusPill,
    ContentIntro,
    CodeRedemption,
  },
  setup() {
    const store = useStore();
    const { event } = useGtag();
    const consumer = computed(() => store.getters['consumerModule/consumer']);
    const showForm = computed(() => store.getters['voucherModule/shouldShowForm']);
    const statusLevel = computed(() => store.getters['consumerModule/statusLevel'] || 'Silver');
    const statusPoints = computed(() => store.getters['consumerModule/statusPoints'] || 0);

    const trackClick = () => {
      event('Click Advantages Button', {
        event_category: 'VoucherRedemption',
        event_label: `Clicked vorteile level: ${statusLevel.value}.`,
      });
    };

    return {
      consumer,
      showForm,
      trackClick,
      statusLevel,
      statusPoints,
      contentBlockFramedImage,
    };
  },
});
