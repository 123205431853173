
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    level: {
      type: String,
      default: 'Silver',
    },
  },
});
